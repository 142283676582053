<template>
  <section
    class="t-why white--bg"
    data-test="why-block"
  >
    <div class="container">
      <h2 class="t-why__title headline-2">
        Почему выбирают ДомИнтернет
      </h2>
      <p class="t-why__subtitle title-5 gray-dark--text">
        Вам необходим простой и легкий поиск, который экономит ваше время и деньги. Вам нужна информация и помощь для быстрого оформления заявки. Вот почему с нами проще сделать правильный выбор
      </p>
      <div class="t-why__grid d-flex flex-column relative">
        <div
          v-for="(line, index) in arr"
          :key="index + line.advanced"
          class="t-why__line d-flex"
        >
          <div
            v-if="disabledPortal"
            class="t-why__line-title title-4"
          >
            {{ line.line }}
          </div>
          <div
            class="t-why__typical"
            :class="index ? 'title-5' : 'headline-6'"
          >
            <p
              v-if="!disabledPortal && index"
              class="title-4"
              style="margin-bottom: 4px;"
            >
              {{ line.line }}
            </p>
            {{ line.typical }}
          </div>
          <div
            class="t-why__advanced"
            :class="index ? 'title-5' : 'headline-3'"
          >
            <p
              v-if="!disabledPortal && index"
              class="title-4"
              style="margin-bottom: 4px;"
            >
              {{ line.line }}
            </p>
            {{ line.advanced }}
            <d-btn
              v-if="disabledPortal && index + 1 === arr.length"
              class="t-why__btn title-4"
              color="primary"
              @click="showDialogAddress"
            >
              Начать поиск
            </d-btn>
          </div>
        </div>
      </div>
      <d-btn
        v-if="!disabledPortal"
        class="t-why__btn title-4"
        color="primary"
        @click="showDialogAddress"
      >
        Начать поиск
      </d-btn>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useMainStore } from '~/store/main/main.store'

const mainStore = useMainStore()
const disabledPortal = computed(() => mainStore.disabledPortal)

const arr = [
  {
    line: '',
    typical: 'Типичный поиск',
    advanced: 'Поиск с ДомИнтернет',
  },
  {
    line: 'Процесс поиска',
    typical: 'Найти и посетить сайты каждого провайдера по отдельности',
    advanced: 'Все провайдеры доступны на одном сайте',
  },
  {
    line: 'Время',
    typical: 'Проверить возможность подключения каждого провайдера',
    advanced: 'Введите свое местоположение один раз и сравните все варианты одновременно',
  },
  {
    line: 'Деньги',
    typical: 'Запомнить все выгодные предложения на сайтах отдельных провайдеров и ничего не упустить',
    advanced: 'Сразу просмотрите все текущие предложения, чтобы выбрать лучшее',
  },
  {
    line: 'Помощь',
    typical: 'Ищите ответы в интернете или звоните на горячую линию каждого провайдера',
    advanced: 'Позвоните 1 раз и получите развернутую информацию от эксперта',
  },
]

const ctx = useNuxtApp()
const showDialogAddress = () => {
  ctx.$event('addressDialog', { redirect: true, label: 'latest-connected', title: 'Введите адрес и получите список доступных провайдеров и тарифов', goToPage: true })
}
</script>

<style scoped lang="scss">
.t-why {
  padding: 64px 0 104px;
  @media (max-width: getBreakpoint(tablet)) {
    padding: 40px 0;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__subtitle {
    margin-bottom: 64px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 48px;
    }
  }

  &__grid {
    @media (max-width: getBreakpoint(tablet)) {
      margin-left: -16px;
      margin-right: -16px;
    }
    &:before {
      content: '';
      position: absolute;
      top: -40px;
      right: 0;
      bottom: -40px;
      width: 46%;
      border-radius: 8px;
      box-shadow: 0px 0px 5px 0px rgba(2, 4, 30, 0.15);
      @media (max-width: getBreakpoint(desktop)) {
        width: 53%;
      }
      @media (max-width: getBreakpoint(tablet)) {
        top: -24px;
        bottom: -24px;
      }
    }
  }

  &__line {

    &:not(:last-child):not(:first-child) {
      border-bottom: 1px solid color(gray-darker);
    }
    &-title {
      flex: 0 0 21%;
      padding: 24px 32px 24px 0;
    }
    &:first-child {
      .t-why__typical {
        border-radius: 8px 0 0 0;
        padding-bottom: 0;
        @media (max-width: getBreakpoint(tablet)) {
          padding-top: 24px;
          border-radius: 0;
        }
      }
      .t-why__advanced {
        border-radius: 8px 8px 0 0;
        padding-bottom: 0;
        margin-top: -40px;
        @media (max-width: getBreakpoint(tablet)) {
          margin-top: -24px;
          padding-top: 24px;
        }
      }
    }
    &:last-child {
      .t-why__typical {
        border-radius: 0 0 0 8px;
        @media (max-width: getBreakpoint(tablet)) {
          border-radius: 0;
        }
      }
      .t-why__advanced {
        border-radius: 0 0 8px 8px;
        margin-bottom: -40px;
        @media (max-width: getBreakpoint(tablet)) {
          margin-bottom: -24px;
          padding-bottom: 24px;
        }
      }
    }
  }
  &__typical {
    flex: 0 0 33%;
    background: color(gray-p);
    padding: 24px 32px;
    @media (max-width: getBreakpoint(desktop)) {
      flex: 0 0 47%;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding: 12px 16px;
    }
  }
  &__advanced {
    flex-grow: 1;
    padding: 24px 32px;
    background: color(blue);
    @media (max-width: getBreakpoint(tablet)) {
      padding: 12px 16px;
    }
  }

  &__btn {
    margin-top: 24px;
    padding: 16px 40px;
    @media (max-width: getBreakpoint(desktop)) {
      margin-top: 48px;
      width: 100%;
      padding: 8px 40px;
      height: 44px;
      max-width: 400px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      height: 42px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }
  }
}
</style>
